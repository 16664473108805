/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    try{Typekit.load({ async: true });}catch(e){}

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// Can also be used with $(document).ready()
jQuery(window).load(function() {



  jQuery('.flexslider').flexslider({
    animation: "slide",
    slideshow: true,
    slideshowSpeed: 5000,           //Integer: Set the speed of the slideshow cycling, in milliseconds
    animationSpeed: 1200,
  });

  jQuery( '#tabmenus a').click( function(e) {

    var buttonlink = jQuery( this );

    jQuery( '#tabmenus a').each( function() {
      jQuery( this ).removeClass( 'active' );
    } );

    jQuery( buttonlink ).addClass( 'active' );

    var tabid = jQuery( this).data( 'tab' );

    jQuery( '.tab').each( function() {
      jQuery( this ).removeClass( 'show' );
      jQuery( this ).addClass( 'hidden' );
    } );
    jQuery( "#" + tabid ).removeClass( 'hidden' ).addClass( 'show' );

    e.preventDefault();
  } );

  jQuery('a.home-blocksdd').on( "click", function(e) {

    e.preventDefault();

    var id = jQuery(this).attr('data-divid');

    var t = jQuery('.'+id);
    var aa = 0;

    if (t.is(':visible')) {
      aa = 1;
    }

    jQuery("div").each(function(){

      if( id !== 'home_reizen' ) {
        jQuery('.home_reizen').slideUp();
      }

      if( id !== 'home_beleving' ) {
        jQuery('.home_beleving').slideUp();
      }

      if( id !== 'home_reiskalender' ) {
        jQuery('.home_reiskalender').slideUp();
      }

    });

    if( aa === 0 ) {
      jQuery('.' + id).slideDown();
    }

  });
});
